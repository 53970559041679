import React from "react";
import { Link } from "react-router-dom";
import { BrandLogo } from "../svg/BrandLogo";
import { Group, Button, Burger, Drawer, ScrollArea, Divider, Space } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setToken, setUser, setUserDetails } from "../store/slices/authSlice";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";

const links = [
  {
    label: "HOME",
    href: "/",
  },
  {
    label: "ABOUT",
    href: "/aboutus",
  },
  {
    label: "PRICING",
    href: "/pricing",
  },
  {
    label: "CONTACT",
    href: "/contactus",
  },
];

function AppHeader() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.auth);
  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const isMediumOrLargerScreen = useMediaQuery("(min-width: 641px)");

  return (
    <>
      <header
        style={{
          height: "60px",
          borderBottom: "1px solid #e9ecef",
          margin: "0px auto",
          zIndex: 999,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "47px 1rem", // Apply padding (47px top and bottom, 1rem left and right)
          maxWidth: "1200px", // Limit the width of the header content
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/">
            <BrandLogo height={65} width={65} />
          </Link>
          {isMediumOrLargerScreen && (
            <Group h="100%" spacing={0} style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              {links.map((link, index) => (
                <React.Fragment key={link.label}>
                  <Link to={link.href}>
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        textDecoration: "none",
                        fontWeight: 400,
                        fontSize: "0.875rem",
                        color: "rgb(33, 33, 33)", // Set text color
                      }}
                    >
                      {link.label}
                    </a>
                  </Link>
                  {index !== links.length - 1 && <Space w="lg" />} {/* Add Space between links */}
                </React.Fragment>
              ))}
              {token !== null && (
                <Link to={"/dashboard"}>
                  <a
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      textDecoration: "none",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      color: "rgb(33, 33, 33)", // Set text color
                    }}
                    href="/dashboard"
                  >
                    Dashboard
                  </a>
                </Link>
              )}
            </Group>
          )}
        </div>

        {isMediumOrLargerScreen && (
          <Group>
            {token === null && (
              <Link to="/login">
                <Button variant="default" className="login-button">LOG IN</Button>
              </Link>
            )}
            {token === null && (
              <Link to="/register">
                <Button>SIGN UP</Button>
              </Link>
            )}
            {token !== null && (
              <Button
                onClick={() => {
                  Cookies.remove("token");
                  Cookies.remove("userDetails");
                  dispatch(setToken(null));
                  dispatch(setUser(null));
                  dispatch(setUserDetails(null));
                  navigate("/login");
                }}
              >
                Logout
              </Button>
            )}
          </Group>
        )}

        {isSmallScreen && (
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            style={{
              display: drawerOpened || isSmallScreen ? "block" : "none",
            }}
          />
        )}
      </header>

      {/* Drawer for mobile view */}
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - 80px)`} mx="-md">
          <Divider my="sm" />

          {links.map((link) => (
            <Link key={link.label} to={link.href} onClick={closeDrawer}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  textDecoration: "none",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "rgb(33, 33, 33)", // Set text color
                }}
              >
                {link.label}
              </span>
            </Link>
          ))}
          
          <Space h="xl" />

          {token !== null && (
            <Link to={"/dashboard"} onClick={closeDrawer}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  textDecoration: "none",
                  color: "#08008c", // Set active tab text color
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
              >
                Dashboard
              </span>
            </Link>
          )}

          <Divider my="sm" />

          <Group style={{ justifyContent: "center" }} grow pb="xl" px="md">
            {token === null && (
              <Link to="/login" onClick={closeDrawer}>
                <Button variant="default" className="login-button">LOG IN</Button>
              </Link>
            )}
            {token === null && (
              <Link to="/register" onClick={closeDrawer}>
                <Button>SIGN UP</Button>
              </Link>
            )}
            {token !== null && (
              <Button
                onClick={() => {
                  Cookies.remove("token");
                  Cookies.remove("userDetails");
                  dispatch(setToken(null));
                  dispatch(setUser(null));
                  dispatch(setUserDetails(null));
                  navigate("/login");
                  closeDrawer();
                }}
              >
                Logout
              </Button>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
}

export default AppHeader;
