import { Box, Center, Text,BackgroundImage } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import SearchBar from "../SearchBar";
import { useMediaQuery } from "@mantine/hooks";

export const Banner = () => {
  const [city, setCity] = useState<string | null>(null);
  

  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.auth);
  return (
    // <Box maw={200} mx="auto">
    <BackgroundImage
      className="mantine-BackgroundImage-root"
      src={"images/background-img.png"}
      radius="sm"
      mx="auto"
    >
      <Center
        mih={{ sm: "45svh", base: "40svh" }}
        w={{ md: "900px", base: "100%" }}
        px={{ md: 60, base: 20 }}
        py={"sm"}
        mx={"auto"}
        sx={{ maxWidth: "980px" }}
      >
        <Box ta={"center"}>
          <Text
            ff={"Merriweather"}
            sx={{
              fontSize: "15px", // Base font size
              "@media (min-width: 360px)": {
                fontSize: "16px", // Font size for screens wider than 480px
              },
              "@media (min-width: 640px)": {
                fontSize: "24px", // Font size for screens wider than 640px
              },
              "@media (min-width: 768px)": {
                fontSize: "30px", // Font size for screens wider than 768px
              },
              "@media (min-width: 1024px)": {
                fontSize: "36px", // Font size for screens wider than 1024px
              },
              "@media (min-width: 1280px)": {
                fontSize: "45px", // Font size for screens wider than 1280px
              },
            }}
          >
            Discover the keys to safe driving with local driving instructors
          </Text>
          <Text
            fz={{ base: 14, sm: 18, md: 18, lg: 18, xl: 18 }}
            c="gray.7"
            style={{ wordBreak: "break-word", whiteSpace: "normal" }}
          >
            Drive Test Pros was born out of a passion for modernizing the way
            driving education is delivered.
          </Text>
          <Text
            fz={{ base: 14, sm: 18, md: 18, lg: 18, xl: 18 }}
            c="gray.7"
            style={{ wordBreak: "break-word", whiteSpace: "normal" }}
          >
            Our app allows learners and instructors to track progress, set
            goals, and celebrate achievements along the way.
          </Text>

          <Box mx="auto" pt={20} w={{ md: "700px", sm:"650px", xs:"500px" }}>
            <SearchBar
              withBorder
              setCity={setCity}
              {...(userDetails?.role === "LEARNER" && {
                onConfirm: () => {
                  navigate("/dashboard/instructors", {
                    state: {
                      city,
                    },
                  });
                },
              })}
              btnProps={{
                bg: "tertiary",
                sx: (theme) => ({
                  ":hover": {
                    background: theme.colors.yellow[8],
                  },
                }),
              }}
            />
          </Box>
        </Box>
      </Center>
    </BackgroundImage>
    // </Box>
  );
};
