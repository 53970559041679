export const EmailVerification = ({
  height = 120,
  width = 120,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120 43.6973V102.268C120 103.49 119.679 104.637 119.111 105.626C118.508 106.694 117.619 107.582 116.55 108.186C115.56 108.748 114.418 109.07 113.198 109.07H6.80161C5.58229 109.07 4.44024 108.748 3.4503 108.186C2.38068 107.582 1.49215 106.694 0.888531 105.626C0.321127 104.637 0 103.49 0 102.268V43.6973C0 41.7922 0.782294 40.0707 2.04507 38.8369H117.955C118.603 39.4691 119.119 40.225 119.47 41.0599C119.821 41.8947 120.001 42.7915 120 43.6973Z"
        fill="#08008C"
      />
      <path
        d="M119.11 105.626C118.506 106.693 117.618 107.582 116.548 108.186L81.6827 73.3687C80.9753 72.6613 80.9753 71.5168 81.6803 70.807C81.8485 70.6387 82.0482 70.5053 82.2679 70.4143C82.4877 70.3232 82.7233 70.2764 82.9612 70.2764C83.1991 70.2764 83.4346 70.3232 83.6544 70.4143C83.8742 70.5053 84.0739 70.6387 84.2421 70.807L119.11 105.626ZM38.3137 73.3687L3.44849 108.186C2.37887 107.582 1.49034 106.693 0.886719 105.626L35.7543 70.807C35.9225 70.6387 36.1222 70.5053 36.342 70.4143C36.5618 70.3232 36.7973 70.2764 37.0352 70.2764C37.2731 70.2764 37.5087 70.3232 37.7284 70.4143C37.9482 70.5053 38.1479 70.6387 38.3161 70.807C39.0211 71.5168 39.0211 72.6613 38.3137 73.3687Z"
        fill="#160AD8"
      />
      <path
        d="M117.954 38.8379H112.499C113.147 39.4701 113.662 40.226 114.014 41.0609C114.365 41.8957 114.545 42.7925 114.544 43.6983V102.269C114.544 106.026 111.499 109.07 107.742 109.07H113.197C116.954 109.07 119.999 106.026 119.999 102.269V43.6983C119.999 41.7932 119.217 40.0717 117.954 38.8379Z"
        fill="#160AD8"
      />
      <path
        d="M117.957 38.8377L69.2348 85.1331C64.0606 90.049 55.943 90.049 50.7688 85.1331L2.04688 38.8377C3.31575 37.5908 5.02443 36.8935 6.80341 36.8965H113.2C115.052 36.8965 116.73 37.6353 117.957 38.8377Z"
        fill="#EAF6FF"
      />
      <path
        d="M83.357 36.8965C80.4162 50.1013 68.6335 59.9717 54.545 59.9717C40.454 59.9717 28.6713 50.1013 25.7305 36.8965H83.357ZM113.198 36.8965H107.743C108.573 36.8965 109.369 37.0452 110.105 37.3176C111.431 37.8087 111.76 39.5401 110.735 40.5144L63.7772 85.1331C61.9696 86.8537 59.7159 88.033 57.2719 88.5373C61.4383 89.4 65.9334 88.2674 69.2325 85.1331L117.954 38.8377C116.685 37.5909 114.977 36.8935 113.198 36.8965Z"
        fill="#C8EFFE"
      />
      <path
        d="M62.1178 98.7025H57.8845C55.5125 98.7025 53.5898 96.7796 53.5898 94.4078V80.5573C53.5898 78.1854 55.5127 76.2627 57.8845 76.2627H62.1178C64.4898 76.2627 66.4125 78.1856 66.4125 80.5573V94.4078C66.4125 96.7796 64.4896 98.7025 62.1178 98.7025Z"
        fill="#99E6FC"
      />
      <path
        d="M66.4108 80.5581V94.4076C66.4108 96.7786 64.4889 98.7029 62.1178 98.7029H59.0394C60.1935 97.9375 60.9565 96.6241 60.9565 95.1319V80.0752C60.9565 78.4116 60.0124 76.9726 58.6289 76.2627H62.1178C64.4889 76.2627 66.4108 78.1846 66.4108 80.5581Z"
        fill="#62DBFB"
      />
      <path
        d="M60.0019 59.9716C76.3062 59.9716 89.5234 46.7544 89.5234 30.4502C89.5234 14.1459 76.3062 0.928711 60.0019 0.928711C43.6977 0.928711 30.4805 14.1459 30.4805 30.4502C30.4805 46.7544 43.6977 59.9716 60.0019 59.9716Z"
        fill="#EAB720"
      />
      <path
        d="M60.0011 0.928711C59.0812 0.928711 58.1719 0.972896 57.2734 1.05523C72.2988 2.43197 84.0672 15.0658 84.0672 30.4502C84.0672 45.8345 72.2985 58.4683 57.2734 59.8451C58.1719 59.9274 59.0812 59.9716 60.0011 59.9716C76.3054 59.9716 89.5225 46.7545 89.5225 30.4502C89.5225 14.1459 76.3054 0.928711 60.0011 0.928711Z"
        fill="#DAA814"
      />
      <path
        d="M55.7324 29.7403L51.5099 25.5178C50.4328 24.4407 48.6862 24.4407 47.6091 25.5178L43.9485 29.1784C42.8713 30.2555 42.8713 32.0022 43.9485 33.0793L53.327 42.4579C55.2707 44.4015 58.4221 44.4015 60.3658 42.4579L78.705 24.1186C79.7821 23.0415 79.7821 21.2949 78.705 20.2178L75.3146 16.8274C74.2375 15.7503 72.4908 15.7503 71.4137 16.8274L58.5006 29.7405C58.1334 30.1075 57.6355 30.3137 57.1164 30.3136C56.5973 30.3136 56.0994 30.1073 55.7324 29.7403Z"
        fill="#FFE188"
      />
      <path
        d="M78.7046 20.2178L75.3141 16.8274C74.237 15.7503 72.4904 15.7503 71.4133 16.8274L70.2862 17.9544C70.8266 18.0584 71.3234 18.3224 71.712 18.7121L73.2497 20.2178C74.3268 21.2949 74.3268 23.0415 73.2497 24.1186L57.2712 39.5728C55.617 41.227 53.0905 41.4679 51.1758 40.3068L53.3268 42.4579C55.2705 44.4015 58.4219 44.4015 60.3656 42.4579L78.7048 24.1186C79.7819 23.0413 79.7819 21.2949 78.7046 20.2178Z"
        fill="#F0D481"
      />
    </svg>
  );
};
